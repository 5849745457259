import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../inputs/text";
import Button from "react-bootstrap/Button";
import { submitCollection } from "../../controllers/data";
import { CollectionFormProps } from "../component-types";
import { useUserContext } from "../../contexts/user-context";

function AddCollectionForm({
  newCollection,
  setFieldValue,
  toggleAddingCollection,
  refreshCollections
}: CollectionFormProps) {
  const { user } = useUserContext();
  const callback = () => {
    refreshCollections();
    toggleAddingCollection();
  }

  const submit = () => {
    return submitCollection(newCollection, user, callback);
  };

  return (
    <div className="CollectionForm">
      <Row className="align-items-start">
        <h2> New Collection </h2>
      </Row>
      <Row>
        <TextInput
          id="task-name-input"
          label="Name"
          fieldName="name"
          value={newCollection.name}
          setFieldValue={setFieldValue}
          colWidth={6}
        />
      </Row>
      <div className="padding" />
      <Row>
        <TextInput
          id="collection-info-input"
          label="Info"
          fieldName="info"
          value={newCollection.info}
          setFieldValue={setFieldValue}
          colWidth={12}
        />
      </Row>
      <div className="padding" />
      <Row>
        <Col xl={3}>
          <Button variant="secondary" onClick={() => toggleAddingCollection()}>
            Cancel
          </Button>
        </Col>
        <Col xl={6} />
        <Col xl={3}>
          <Button variant="success" onClick={() => submit()}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddCollectionForm;
