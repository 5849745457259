import { useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { collectionProps, newCollectionProps, sourceProps, newSourceProps } from "../data-types";
import { DataManagerProps } from "../component-types";
import CollectionManager from "./data-manager-components/collection-manager";
import SourceManager from "./data-manager-components/source-manager";
import { getAndSetUserCollections } from "../../controllers/data";
import { useUserContext } from "../../contexts/user-context";

function DataManager({}: DataManagerProps) {
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const { user } = useUserContext();
  const [userCollections, setUserCollections] = useState<Array<collectionProps>>([]);
  const fetchCollections = useCallback( async () => {
    setIsLoading(true)
    try {
      getAndSetUserCollections(user, setUserCollections)
    } catch (err) {
      console.error("Failed to fetch Teams");
    } finally {
      setIsLoading(false);
      return;
    }
  }, [user])
  useEffect(() => {
    fetchCollections();
  }, [user, fetchCollections]);
  const [selectedCollection, setSelectedCollection] = useState<collectionProps | null>(null)

  return isLoading ? (
    <div/>
  ) : (
    <div className="DataManager__Page">
      {
        !selectedCollection && (
          <CollectionManager
            userCollections={userCollections}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            refreshCollections={fetchCollections}
          />
        )
      }
      {
        selectedCollection && (
          <SourceManager
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
          />
        )
      }
    </div>
  );
}

export default DataManager;
