import { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import AppNavBar from "../components/widgets/nav-bar";
import DataManager from "../components/data-manager/data-manager";
import { UserHomePageProps } from "../components/component-types";
import { getAndSetUserCollections } from "../controllers/data";
import { useUserContext } from "../contexts/user-context";
import { collectionProps } from "../components/data-types";

const HomePage = ({}: UserHomePageProps) => {
  const { user } = useUserContext();
  
  const [userCollections, setUserCollections] = useState<Array<collectionProps>>([]);
  
  useEffect(() => {
    getAndSetUserCollections(user, setUserCollections);
  }, [user]);
  
  return (
    <div>
      <AppNavBar />
      <Container
        style={{
          paddingTop: "20px",
        }}
      >
        <DataManager/>
      </Container>
    </div>
  );
};

export default HomePage;
