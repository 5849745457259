import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { sourceProps, newSourceProps } from "../../data-types";
import { SourceManagerProps } from "../../component-types";
import SourcesGrid from "../../widgets/sources-grid";
import AddSourceForm from "../../forms/add-source";

function SourceManager({
  selectedCollection,
  setSelectedCollection
}: SourceManagerProps) {
  const [addingSource, setAddingSource] = useState<boolean>(false);
  const [newSource, setNewSource] = useState<newSourceProps>({
    collectionId: -1,
    name: "",
    info: "",
  });

  const setFieldValue = (keyOrObject: any, value: any): void => {
    if (typeof keyOrObject === "object") {
      setNewSource((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewSource((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };

  const toggleAddingSource = () => {
    return setAddingSource(!addingSource);
  };

  return (
    <div className="SourceManager__Page">
      {!addingSource && (
        <div>
        <Stack direction="horizontal" gap={3} border-radius="10px">
          <div className="lgHeader" style={{ fontSize: '24px' }}>
            {selectedCollection.name}
          </div>

          <div className="ms-auto">
            <div className="medium-padding" />
            <Button variant="success" onClick={() => toggleAddingSource()}>
              Add New Source
            </Button>
          </div>

        </Stack>

        <SourcesGrid
          userCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
        />

      </div> //component for showing collections sources
      )}
      {addingSource && (
        <div className="Add">
          <AddSourceForm
            newSource={newSource}
            setFieldValue={setFieldValue}
            toggleAddingSource={toggleAddingSource}
          />
        </div>
      )}
    </div>
  );
}

export default SourceManager;
