import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { sourceProps, sourcesGridProps} from "../data-types";
import { getAndSetCollectionSources } from "../../controllers/data";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/user-context";
import { Col } from "react-bootstrap";

function SourcesGrid({ userCollection, setSelectedCollection}: sourcesGridProps) {
  
    const [collectionSources, setCollectionSources] = useState<Array<sourceProps> | [] > ([]);
    const { user } = useUserContext();

    useEffect(() => {
      getAndSetCollectionSources(user, userCollection.collectionId, setCollectionSources);
    }, [userCollection]);


    function OpenSource(source: ICellRendererParams<any, any, any>){
      //Implement setting the selected data source and rendering source data here
      <Alert variant="success">
          You selected source id = {source.node.data.sourceId}
      </Alert>  
    }
    
    function OpenSourceButton(props: ICellRendererParams){
      return (
        <Button variant="success" onClick= {() => OpenSource(props)}> 
          Open
        </Button>
      );
    }

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: 'name',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1.5,
      headerName: 'Description',
      field: 'info',
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Created',
      field: 'createdAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Updated',
      field: 'updatedAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: .5,
      field: 'action',
      headerName: '',
      cellRenderer: OpenSourceButton,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
  ];

  if (collectionSources){
    return (
        // wrapping container with theme & size
        <div
        className="ag-theme-alpine" // applying the Data Grid theme
        style={{
          height: 500
        }} // the Data Grid will fill the size of the parent container
        >
          <AgGridReact
              rowData={collectionSources}
              columnDefs={columnDefs}
              rowHeight={50}
          />

        <Col xl={3}>
        <div style={{ marginTop: "1cm"}}>

          <Button variant="secondary" onClick={() => setSelectedCollection(null)}>
            Back
          </Button>

        </div>
        </Col>
        <Col xl={6} />
        </div>
    );
  } else {
    return (
      <div>
       <h1> No sources to display</h1>;

       <Col xl={3}>
          <div style={{ marginTop: "1cm"}}>
            <Button variant="secondary" onClick={() => setSelectedCollection(null)}>
              Back
            </Button>
        </div>  
        </Col>
        <Col xl={6} />
      </div>
    )
  }
}

export default SourcesGrid;